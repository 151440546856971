@font-face {
    font-family: "MrEavesXLSanNarOT-Bold" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Bold.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Book" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Book.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Heavy" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Heavy.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Light" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Light.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Reg" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Reg.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Thin" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Thin.otf");
}
@font-face {
    font-family: "MrEavesXLSanNarOT-Uiltra" ;
    src: url("./assets/fonts/MrEavesXLSanNarOT-Ultra.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Bold" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Bold.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Book" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Book.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Heavy" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Heavy.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Light" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Light.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Reg" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Reg.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Thin" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Thin.otf");
}
@font-face {
    font-family: "MrEavesXLSanOT-Uiltra" ;
    src: url("./assets/fonts/MrEavesXLSanOT-Ultra.otf");
}
@font-face {
    font-family: "Poppins-Bold" ;
    src: url("./assets/fonts/Poppins-Font/Poppins-Bold.ttf");
}
@font-face {
    font-family: "Poppins-Light" ;
    src: url("./assets/fonts/Poppins-Font/Poppins-Light.ttf");
}
@font-face {
    font-family: "Poppins-Medium" ;
    src: url("./assets/fonts/Poppins-Font/Poppins-Medium.ttf");
}
@font-face {
    font-family: "Poppins-Regular" ;
    src: url("./assets/fonts/Poppins-Font/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins-SemiBold" ;
    src: url("./assets/fonts/Poppins-Font/Poppins-SemiBold.ttf");
}